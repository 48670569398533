.widget-cta {
    text-align: center;
    i {
        color: $yellow;
        font-size: 4.5rem;
    }

    h4 {
        margin: 2.5rem 0;
    }

    .fa-product-search + h4 {
        margin-top: 1.5rem;
    }
}
