﻿.widget-assets {
    .grid-x {
        margin-bottom: -2.5rem;
    }

    h4 {
        padding-bottom: 1rem;
        border-bottom: 1px solid $white;
    }

    .asset-column {
        margin-bottom: 2.5rem;
    }

    .fa-image {
        font-size: 1.25rem;
        position: relative;
        top: .125rem;
    }

    .icon-links {
        margin-bottom: 0;

        li {
            + li {
                margin-top: 1.25rem;
            }
        }
    }

    .indent {
        font-size: 1.25rem;
        padding-left: 1rem;
    }

    .icon-link {
        font-size: 1.125rem;
        letter-spacing: 2px;
        line-height: 1.4;
        align-items: flex-start;

        .emphasize {
            font-weight: 300;
            display: block;
            width: 100%;
            color: inherit;
        }
    }

    @include breakpoint(medium) {
        .grid-x {
            margin-bottom: -4.5rem;
        }

        .asset-column {
            margin-bottom: 4.5rem;

            > div {
                width: 464px;
                max-width: 100%;
            }

            &:nth-child(2n) {
                display: flex;
                justify-content: flex-end;
            }
        }
    }
}

.no-padding {
    padding-top: 0 !important;
}

.asset-top-wrapper:not(:first-child) {
    padding-top: 6rem;
}

.related-products-wrapper {
    padding-top: 0 !important;
}

.bottom-space {
    padding-bottom: 6rem;
}
