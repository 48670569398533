﻿.widget-links {
    margin-bottom: -1.5rem;

    .inline-list {
        margin: 0 -2rem;

        li {
            margin: 0 2rem 1.5rem;
        }
    }

    .icon-link {
        font-size: 1.5rem;
        font-family: $barlowCondensed;
        letter-spacing: 1.6px;
        font-weight: 700;

        i {
            font-size: 2rem;
            width: 42px;
        }
    }

    &.layout-vertical {
        .inline-list {
            flex-direction: column;

            li {
                flex: 0 0 100%;
            }
        }

        .icon-link {
            width: 100%;
            background-color: rgba($black,.55);
            padding: 1.5rem 2.125rem;
            transition: all .3s;

            &:hover {
                background-color: rgba($black, .8);
            }
        }
    }

    @include breakpoint(medium) {

        &.layout-horizontal {

            .inline-list {
                justify-content: center;
            }
        }
    }
}
