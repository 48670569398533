﻿.widget-images {
    &.variant-circles {
        max-width: 500px;
        margin-bottom: 2rem;

        img {
            border: 12px solid $white;
            border-radius: 145px;
            box-shadow: 0 0 10px 0 rgba($black,.5);
        }

        .aligner {
            padding-right: 42%;

            + .aligner {
                margin-top: -10%;
            }
        }

        .aligner:nth-child(2n + 1) {
            padding: 0 0 0 42%;
        }

        @include breakpoint(large) {
            margin-bottom: 0;
        }
    }
}
