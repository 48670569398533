@mixin backgroundStyling {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
}

.primary-carousel {
    .slide-off {
        padding-left: 1rem;
        padding-right: 1rem;

        > div {
            cursor: default !important;
        }

        @media only screen and (min-width: 75em) {
            padding-left: 0;
            padding-right: 0;
        }
    }
}

.widget-carousel {
    .slides {
        > div {
            display: flex;
        }
    }

    .slide {
    }

    .controls {
        $dimensionControl: 4.325rem;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        z-index: 2;
        width: 100%;

        a {
            display: flex;
            width: $dimensionControl;
            height: $dimensionControl;
            box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.5);
            border-radius: $dimensionControl;
            justify-content: center;
            align-items: center;
            background-color: $yellow;
            position: absolute;
            opacity: 1;
            transition: opacity .3s;
            top: -$dimensionControl / 2;

            &.disabled {
                opacity: .5;
                pointer-events: none;
            }

            .fa {
                color: $black;
                font-size: 1.25rem;
            }

            $controlDistance: 0.75rem;

            &.prev {
                left: $controlDistance;
            }

            &.next {
                right: $controlDistance;
            }
        }
    }

    .intro {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        width: calc(100% + #{$smallGutters * 2});
        margin: 0 #{-$smallGutters} .5rem;

        > div {
            margin: 0 #{$smallGutters} 1.5rem;
        }

        .rte {
            width: 100%;
        }

        p {
            font-size: 1.125rem;
        }

        .link {
            flex: 1 1 auto;
        }

        .icon {
            font-size: 2.5rem;
            color: $yellow;
            margin-bottom: 1rem;
        }

        h4 {
            margin-bottom: 0;
        }

        h5 {
            font-size: 1.5rem;
            text-transform: none;
        }
    }

    .follow-up {
        text-align: center;

        .icon {
            font-size: 2.5rem;
            color: $yellow;
            margin-bottom: 2.5rem;
        }
    }

    &.layout-carousel {
        h5 {
            margin-bottom: 0;
        }
    }

    &.layout-with-title {
        border-bottom: 1px solid $white;
        padding-bottom: 3.5rem;

        h3 {
            font-family: $barlow;
            font-size: 1.875rem;
            text-align: center;
            position: relative;
            align-items: center;
            display: flex;
            flex-wrap: nowrap;
            text-transform: none;
            margin-bottom: 3.5rem;

            &::before, &::after {
                content: '';
                height: 1px;
                background-color: $white;
                flex: 1 1 auto;
                position: relative;
                top: 1px;
            }

            &::before {
                margin-right: 2.5rem;
            }

            &::after {
                margin-left: 2.5rem;
            }
        }
    }

    @include breakpoint(small only) {
        &.layout-carousel {

            .slides {
                padding: 0 .75rem;
            }
        }
    }

    @include breakpoint(medium) {
        .controls {
            a {
                $controlDistance: 3.75rem;

                &.prev {
                    left: $controlDistance;
                }

                &.next {
                    right: $controlDistance;
                }
            }
        }

        .intro {
            width: calc(100% + #{$mediumGutters * 2});
            margin: 0 #{-$mediumGutters} 0rem;

            > div {
                margin: 0 #{$mediumGutters} 1.5rem;
            }

            .rte {
                width: 600px;
                max-width: 100%;
            }

            .link {
                text-align: right;
            }
        }

        &.layout-carousel {

            .slides {
                padding: 0 3.5rem;
            }

            .controls {
                a {
                    $controlDistance: 1.25rem;

                    &.prev {
                        left: $controlDistance;
                    }

                    &.next {
                        right: $controlDistance;
                    }
                }
            }
        }
    }

    @import '_video.scss';
    @import '_reviews.scss';
    @import '_relatedProducts.scss';
    @import '_images.scss';
    @import '_news.scss';
}

.content-full {
    .follow-up {
        padding-right: $smallGutters;
        padding-left: $smallGutters;
    }
}

@include breakpoint(medium) {
    .content-full {
        .follow-up {
            padding-right: $mediumGutters;
            padding-left: $mediumGutters;
        }
    }
}
