﻿.buy-amazon-button {
    min-width: 220px;
    min-height: 70px;
    border: 2px solid $white;
    padding: .0625rem 1.5rem 0;
    display: inline-flex;
    align-items: center;
    color: $white;
    transition: .3s all;
    text-transform: uppercase;
    font-weight: 600;
    font-size: 1rem;
    letter-spacing: 2px;
    justify-content: space-between;
    box-shadow: none;

    &:hover {
        color: $white;
        box-shadow: 0 0 10px inset $white;
    }

    &::after {
        content: url('/images/amazon-logo.svg');
        font-size: 1.5rem;
        margin-left: 1rem;
        position: relative;
        top: .625rem;
    }
}
