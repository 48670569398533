﻿.widget-text-on-image {
    .brands {
        list-style-type: none;
        text-align: center;
        margin: 4.5rem -1.5rem -2rem;

        .cell {
            margin: 0 1.5rem 2rem;
            width: calc(50% - 3rem);
        }

        img {
            min-width: 50%;
            min-height: 50px;
        }
    }

    .content {
        margin-bottom: -3.5rem;

        > .cell {
            margin-bottom: 3.5rem;
        }
    }

    .text {
        max-width: 580px;
    }

    .side-box {
        border: 1px solid $white;
        padding: 4.5rem 4.5rem 6rem;
        text-align: center;
        max-width: 500px;
    }

    .grid-container {
        width: 100%;
    }

    .fill-container {
        padding: 3rem 0;
    }

    @include breakpoint(medium) {
        .brands {
            .cell {
                width: calc(25% - 3rem);
            }
        }
    }

    @include breakpoint(large) {
        .fill-container {
            min-height: 929px;
            display: flex;
            align-items: center;
        }

        .content {
            justify-content: space-between;
        }

        .brands {
            width: calc(50vw - #{$mediumGutters});
            max-width: 732px;
            img {
                min-width: 80%;
            }
        }


        .background {
            width: 50%;
            left: 50%;

            &::after {
                content: '';
                z-index: 1;
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background-image: linear-gradient(to right, $black 0, rgba($black, .7) 40%);
            }
        }

        .right-aligned {
            .content {
                flex-direction: row-reverse;
            }

            .background {
                left: 0;

                &::after {
                    background-image: linear-gradient(to left, $black 0, rgba($black, .7) 40%);
                }
            }
        }
    }

    @media only screen and (min-width: $grid-container + $mediumGutters) {
        .side-box {
            transform: translateX(calc(50vw - #{($grid-container + $mediumGutters) / 2}));
        }
    }


    @media only screen and (min-width: #{$grid-container + 9rem + $mediumGutters}) {
        .side-box {
            transform: translateX(4.5rem);
        }
    }
}
