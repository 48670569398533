﻿.widget-feed-detail {
    .divider {
        margin: 0 1rem;
    }

    .info {
        padding-top: 1rem;
        margin-bottom: 2rem;
    }

    .inline-list {
        height: 38px;
    }

    .links {
        display: flex;
        flex-wrap: wrap;
        margin: 0 -#{$smallGutters};

        div {
            margin: 0 #{$smallGutters} 2rem;
            flex: 0 0 auto;
        }

        li {
            display: flex;
            align-items: center;
        }

        h6 {
            padding-bottom: 1.25rem;
            margin-bottom: 1.25rem;
            border-bottom: 2px solid $grey-light;
        }

        a {
            color: $yellow;
            font-size: 1.5rem;

            &:hover {
                color: lighten($yellow, 10%);
            }
        }

        .fa-email {
            &::before {
                position: relative;
                font-size: .75em;
                top: -.125em;
            }
        }
    }

    @include breakpoint (small only) {
        > .grid-x {
            margin-bottom: -2rem;
        }

        .cell {
            margin-bottom: 2rem;
        }
    }

    @include breakpoint(medium) {
        > .grid-x {
            justify-content: space-between;
        }

        .details {
            max-width: 500px;
        }

        .links {
            display: flex;
            flex-wrap: wrap;
            margin: 0 -1.875rem;

            div {
                margin: 0 1.875rem 2rem;
            }
        }

        .inline-list {
            margin: 0 -.75rem;

            > li {
                margin: 0 .75rem;
            }
        }
    }
}
