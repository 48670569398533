﻿.widget-icon-list {
    > ul {
        margin-bottom: 0;
    }

    .item {
        display: flex;
        flex-wrap: nowrap;
        + .item {
            margin-top: 2.5rem;
        }
    }

    h4 {
        margin-bottom: .625rem;
    }

    i {
        color: $yellow;
        font-size: 2rem;
    }

    .icon-container {
        width: 2.5rem;
        height: 2.25rem;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 1rem;
    }

    .fa-email {
        font-size: 1.5rem;
    }

    .revealer {
        text-transform: none;
        font-size: 1.125rem;
    }

    @include breakpoint(medium) {
        .icon-container {
            margin-right: 3rem;
        }
    }
}
