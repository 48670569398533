﻿.widget-services {
    .category {
        position: relative;
        z-index: 1;
        text-align: right;

        + .category {
            margin-top: 2rem;
        }
    }

    .container {
        display: inline-block;
        padding: 0 #{$smallGutters};
        position: relative;
        text-align: left;
    }

    .buttons li + li {
        margin-top: 1rem;
    }

    h4 {
        margin-bottom: 1.5rem;
    }

    .tile {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        background-size: cover;
        position: relative;
        z-index: 1;
        padding: 2.5rem 1.5rem 2rem;
        text-align: center;

        &::before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: $black;
            opacity: .9;
            z-index: -1;
        }


        .icon {
            font-size: 2.75rem;
            margin-bottom: 1rem;
            display: block;

            &.fa-water {
                font-size: 1.75rem;
            }
        }
    }

    .service {
        color: $yellow;
        position: relative;
        z-index: 2;
        pointer-events: none;
    }

    @include breakpoint(small only) {
        .service {
            font-size: 3rem;
            margin-bottom: -1.5rem;
            text-align: center;
        }
    }

    @include breakpoint(medium) {
        .container {
            width: calc(50vw + #{$grid-container / 2 + 3rem});
            max-width: 100%;
            padding: 0 #{$mediumGutters};
        }

        .service {
            margin-bottom: -2.5rem;
            padding-left: 3rem;
        }

        .background {
            position: absolute;
            left: 0;
            top: 0;
            z-index: -1;
            pointer-events: none;
        }

        .tile {
            height: 420px;
            width: 371px;
            overflow: hidden;
            padding: 4.5rem 2rem 3rem;

            > div {
                position: relative;
            }

            .hidden-block {
                visibility: hidden;
                opacity: 0;
                display: none;
            }

            &::before {
                opacity: .7;
                transition: opacity .3s;
            }

            &:hover {
                &::before {
                    opacity: .9;
                }
            }
        }

        .category {
            + .category {
                margin-top: 6rem;
            }
        }

        .right-aligned {
            text-align: left;

            .grid-x {
                justify-content: flex-end;
            }

            .service {
                text-align: right;
                padding-left: 0;
                padding-right: 3rem;
            }

            .background {
                left: auto;
                right: 0;
            }
        }
    }

    @media print, screen and (min-width: #{92.75rem + 2 * $mediumGutters}) {
        .container {
            min-width: 92.75rem + 2 * $mediumGutters;
        }
    }
}
