﻿.video {
    overflow: hidden;
    position: relative;
    z-index: 1;
    text-align: left;

    iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }

    .fill {
        img {
            position: absolute;
            transform-origin: center;
            transform: translateY(-50%);
            width: 100%;
            left: 0;
            top: 50%;
            z-index: 2;
        }
    }

    .background {
        &::after {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            width: 100%;
            background-color: $black;
            z-index: 2;
            opacity: .5;
            transition: .3s opacity;
        }
    }

    h4 {
        font-size: 1.5rem;
        line-height: 1.75rem;
        margin-bottom: .5rem;
    }

    h5 {
        font-size: 1.125rem;
        display: flex;
        align-items: center;
        color: $yellow;

        i {
            font-size: 1.5rem;
            margin-right: 1rem;
            position: relative;
            top: -1px;
        }
    }

    .overlay-text {
        z-index: 2;
        padding: 1rem 1.5rem;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
    }

    lazy-element {
        z-index: 3;
    }

    &:hover {
        .background {
            &::after {
                opacity: .7;
            }
        }
    }

    @include breakpoint(medium) {
        h4 {
            font-size: 2.25rem;
            line-height: 2.875rem;
            margin-bottom: 1.75rem;
        }

        .overlay-text {
            padding: 3rem 6.5rem;
        }
    }
}
