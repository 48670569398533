﻿.widget-product-search-wizard {
    overflow: hidden;

    .wrapper {
        display: flex;
        flex-wrap: nowrap;
        width: 200%;
        margin: 0 -.5rem;
    }

    .step {
        margin: 0 .5rem;
        width: calc(50% - 1rem);
    }

    .tile {
        border: 1px solid $black;
        display: block;
        background-color: transparent;
        padding: 2rem 1.5rem 1rem;
        color: $black;
        font-weight: 700;
        text-align: center;
        transition: all .3s;

        &:hover {
            color: $yellow;
            border-color: $yellow;
        }

        .icon {
            width: 100%;
            font-size: 1.75rem;
            margin-bottom: 1.5rem;

            &.fa-water {
                font-size: 1.5rem;
            }
        }
    }

    .step-1 {
        .rte {
            margin-bottom: 4.5rem;
        }

        .grid-x {
            margin-bottom: -1rem;
        }

        .cell {
            margin-bottom: 1rem;
        }

        .shrink {
            max-width: calc(100% - #{$mediumGutters * 2});
        }

        .button {
            padding-left: 0;
            padding-right: 0;
            width: 280px;
            max-width: 100%;
        }
    }

    .step-2 {
        .grid-x {
            margin: 0 -.5rem .5rem;
        }

        .cell {
            margin: 0 .5rem 1rem;
            width: 151px;
        }

        .rte {
            margin-bottom: 2.25rem;
        }

        .category {
            display: none;
        }
    }

    .button {
        width: 280px;
    }

    @include breakpoint(medium) {

        .back-button {
            left: $mediumGutters;
        }
    }
}

.reveal {
    .widget-product-search-wizard {
        .back-button {
            position: absolute;
            top: -2.75rem;
            left: $smallGutters;
            display: none;
        }
        @media screen and (min-width: 75rem) {
            .back-button {
                left: -3.5rem;
            }
        }
    }
}
