﻿$boxButtonSize: 1.9375rem;
$boxButtonIconSize: 1.25rem;
.box-button {
    display: flex;
    width: $boxButtonSize;
    height: $boxButtonSize;
    justify-content: center;
    align-items: center;
    background-color: $white;
    position: absolute;
    opacity: 1;
    transition: all .3s;
    color: $black;

    &:hover {
        color: $black;
        background-color: darken($white, 10%);
    }

    &.active, &:focus {
        background-color: $yellow;
    }

    &::after {
        font-family: $iconFont;
        font-size: $circleIconSize;
    }

    &.disabled {
        opacity: .5;
        pointer-events: none;
    }
}
