﻿.widget-newsletter {
    &.variant-with-intro {
        .cell {
            display: flex;
            justify-content: center;
            align-items: center;
        }

        .intro {
            border-right: 1px solid $grey;

            @include breakpoint(small only) {
                padding-bottom: 1rem;
                margin-bottom: 1.5rem;
                border-bottom: 1px solid $grey;
                border-right-width: 0;
            }
        }
    }
}
