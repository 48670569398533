﻿.widget-products-by-category {
    padding: 2.5rem #{$smallGutters};

    .sub-category-item {
        display: none;

        &.active {
            display: inherit;
        }
    }

    .info {
        padding-bottom: 300px;
    }

    h3 {
        color: $yellow;
    }

    .inline-list {
        margin-bottom: -1rem;

        > li {
            margin-bottom: 1rem;
        }
    }

    .category-group {
        position: relative;

        + .category-group {
            margin-top: 2.5rem;
        }
    }

    .controls {
        display: none;
    }

    .children {
        position: relative;
        z-index: 4;
        margin-top: -300px;

        ul {
            list-style-type: none;
            position: absolute;
            left: 0;
            top: 0;
        }

        .grid-x {
            flex-wrap: nowrap;
        }

        .cell {
            flex: 0 0 auto;
            width: 268px;
            height: 380px;
            display: flex;
        }
    }

    .product-card {
        width: 100%;
    }

    .products-container, .summary {
        position: absolute;
        top: 0;
        left: 0;

        &.active {
            position: relative;
        }
    }

    .products {
        height: 380px;
        margin-top: 2.5rem;
        margin-bottom: 2.5rem;
        position: relative;
    }

    .image-spacer {
        height: 170px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 1rem;
    }

    h2, h5 {
        margin-bottom: 0;
    }

    .circle-button {
        position: absolute;
        bottom: 260px;
        z-index: 5;

        &--previous {
            left: 0;
        }

        &--next {
            right: 0;
        }
    }

    .rte {
        margin-top: 2rem;
        position: relative;
    }

    @include breakpoint(medium) {
        padding: 9rem 0 9rem #{$mediumGutters};

        .category-group {
            + .category-group {
                margin-top: 6rem;
            }
        }

        .intro {
            display: flex;
            position: relative;

            &::after {
                content: '';
                display: block;
                position: absolute;
                top: 0;
                left: 0;
                height: 100%;
                width: 100%;
                background-image: linear-gradient(to right, $black 0, $black 705px, transparent 905px), linear-gradient(to right, $black 0, $black 705px, transparent 905px);
                z-index: 2;
            }
        }

        .info {
            flex: 0 1 800px;
            width: 100%;
            max-width: 800px;
            position: relative;
            z-index: 3;
            padding-right: $mediumGutters;
        }

        .rte {
            max-width: 515px;
        }

        .background {
            height: 647px;
            position: relative;
            flex: 1 1 auto;

            img {
                position: absolute;
                left: -145px;
                top: 0;
                max-width: none;
            }
        }
    }

    @media print, screen and (min-width: $grid-container) {
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-end;

        .category-group {
            width: calc(50vw + #{$grid-container / 2 + .4375rem - $mediumGutters * 2})
        }
    }

    @media only screen and (min-width:1360px) {
        .children .cell {
            width: 290px;
        }
    }
}
