﻿&.layout-related-products {
    h3, .slides {
        max-width: 100%;
        width: $grid-container - $smallGutters * 2;
        margin-left: auto;
        margin-right: auto;
    }

    h3 {
        font-size: 1.875rem;
        color: $yellow;
        font-family: $barlow;
        text-transform: none;
        letter-spacing: .5px;
    }

    .loader-wrap {
        width: 100%;
    }

    .slides {
        overflow: visible;
    }

    .carousel-block:not(.ng-hide) + .carousel-block {
        margin-top: 2rem;
    }

    @include breakpoint(medium) {
        h3, .slides {
            width: $grid-container - $mediumGutters * 2;
        }

        .link-block {
            text-align: right;
        }

        .carousel-block:not(.ng-hide) + .carousel-block {
            margin-top: 6rem;
        }
    }
}
