﻿.outline-button {
    border: 2px solid $white;
    padding: 1.25rem 1.5rem 1.1875rem;
    line-height: 1.625rem;
    text-align: center;
    display: inline-block;
    font-size: 1.5rem;
    font-weight: 600;
    color: $white;
    background-color: transparent;
    text-transform: uppercase;
    transition: .3s all;
    letter-spacing: 1.5px;
    font-family: $barlowCondensed;

    &:hover {
        cursor: pointer;
        background-color: $black;
        border-color: $black;
        color: $white;
    }

    .emphasize {
        font-size: 2.125rem;
        line-height: 2rem;
        display: block;
        text-align: center;
        letter-spacing: 2px;
        margin-bottom: .5rem;
        font-weight: 700;
        color: inherit;
    }
}
