﻿.thumbnail-tile {
    display: block;

    .image-container {
        width: 100%;
        overflow: hidden;
    }

    .fill {
        background-color: $black;
        opacity: .3;
        transition: opacity .3s;
        z-index: 2;
    }

    h5 {
        margin-bottom: 0;
        text-transform: none;
    }

    h6{
        font-size: .75rem;
        color: $yellow;
    }

    .text {
        padding: 2.5rem 1.5rem 0;
    }

    img {
        transition: all .3s;
        transform-origin: center;
        transform: scale(1,1);
    }

    &:hover {
        .fill {
            opacity: 0;
        }

        img{
            transform: scale(1.2, 1.2);
        }
    }
}

.simple-tile {
    min-height: 640px;
    padding: 1rem;
    display: flex;
    align-items: center;
    &, h1, h2, h3, h4, h5, h6 {
        color: $white;
    }

    > div {
        width: 100%;
    }

    @include breakpoint(medium) {
        padding: 1rem 3rem;
    }

    @include breakpoint(large) {
        padding: 1rem 5rem;
    }
}

.review {
    text-align: center;

    h4 {
        font-weight: normal;
        text-transform: none;
        color: $yellow;
        font-size: 1.5rem;
        font-weight: 600;
        margin-bottom: .5rem;
    }

    stars {
        justify-content: center;
        margin-bottom: 1.5rem;
    }

    p {
        margin-bottom: 1.5rem;

        &:last-child {
            margin-bottom: 0;
        }
    }

    @include breakpoint(small only) {
        p {
            padding: 0 $smallGutters;
        }
    }
}