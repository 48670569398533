﻿.widget-image {
    &.variant-with-caption {
        img {
            margin-bottom: 2rem;
        }

        .spacer {
            display: block;
        }

        .fa-angle-up {
            color: $red;
            font-size: 3rem;
            margin-right: 2rem;
            position: absolute;
            left: 0;
            top: -.875rem;
        }

        .caption {
            margin-top: 2rem;
            position: relative;
            padding-left: 3rem;
            margin-bottom: 0;
            font-size: 1rem;
            font-family: $barlow;
            color: $blue;
        }
    }
}

.grid-area:first-child {
    .grid-image {
        img {
            left: auto;
            right: 0;
        }
    }
}
.grid-image {
    .image-placeholder {
        font-size: 5rem;
    }
}

$circleDimension: 13.625rem;
.circle-container {
    border-radius: 50%;
    background: $white;
    padding: 8px;
    box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.8); 
    width: $circleDimension;
    height: $circleDimension;

    img {
        border-radius: 100%;
    }
}