﻿.video-wrapper {
    &, .video-container {
        position: relative;
    }

    &.expand {
        overflow: hidden;

        > div {
            position: relative;
        }
    }

    .video {
        position: absolute !important;
        top: 0;
        left: 0;
        background-color: $black;

        &, iframe, > div {
            height: 100%;
            width: 100%;
        }
    }

    @include breakpoint(medium) {
        &.expand {
            > div {
                &::before {
                    position: absolute;
                    content: '';
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    z-index: 1;
                }
            }
        }
    }
}
