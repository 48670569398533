﻿.widget-list-intro {
    h4 {
        color: $white;
    }

    p {
        max-width: 430px;
    }

    @include breakpoint(small only) {
        .image {
            margin-top: 1.5rem;
        }
    }

    @include breakpoint(medium) {
        display: flex;
        align-items: center;
        .text {
            flex: 1 1 auto;
            padding-right: 5rem;
        }
        .image {
            flex: 0 0 auto;
            max-width: 50%;
        }
    }
}
