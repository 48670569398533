﻿$circleButtonSize: 4.325rem;
$circleIconSize: 1.25rem;
.circle-button {
    display: flex;
    width: $circleButtonSize;
    height: $circleButtonSize;
    box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.5);
    border-radius: $circleButtonSize;
    justify-content: center;
    align-items: center;
    background-color: $yellow;
    position: absolute;
    opacity: 1;
    transition: opacity .3s;

    &::after {
        font-family: $iconFont;
        color: $black;
        font-size: $circleIconSize;
    }

    &.disabled {
        opacity: .5;
        pointer-events: none;
    }

    &--previous {
        &::after {
            content: '\f012';
        }
    }

    &--next {
        &::after {
            content: '\f011';
        }
    }
}
