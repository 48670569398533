﻿#footer {
    .footer-navigation {
        a {
            color: $white;
        }

        .title, a {
            font-weight: 600;
            font-family: $barlow;
            letter-spacing: 0.5px;
        }

        .title {
            color: $yellow;
        }

        li + li {
            margin-top: .5rem;
        }
    }

    .social-links {
        i {
            margin-right: 1rem;
            font-size: 2rem;
        }

        .icon-link {
            display: flex;
        }
    }

    .block-links {
        .cell {
            padding: 0 2.5rem;
        }

        a {
            text-align: center;
            display: block;
            color: $white;
        }

        span {
            color: $yellow;
            text-transform: uppercase;
            font-weight: 700;
            letter-spacing: 1.5px;
        }

        img {
            height: 83px;
        }

        p {
            font-size: .875rem;
            margin: 1rem 0;
        }
    }

    .link:hover {
        color: $yellow;
    }

    a:hover {
        color: $yellow;
    }

    .socialiconcolor {
        color: $white;
        font-size: 1.563rem;
    }


    ul {
        list-style-type: none;
    }

    .base {
        color: $offWhite;

        p {
            font-size: .875rem;
            margin-bottom: 0;
        }

        a {
            color: $offWhite;

            &:hover {
                color: $yellow;
            }
        }
    }

    @include breakpoint(small only) {
        .block-links {
            .cell {
                border-top: 1px solid $white;
                padding-top: 2.5rem;

                + .cell {
                    margin-top: 2.5rem;
                }
            }
        }
    }

    @include breakpoint(medium) {
        .top {
            display: flex;
            justify-content: space-between;
            align-items: flex-start;
        }

        .block-links {
            margin: 0 -2.5rem;

            .cell {
                border-left: 1px solid $white;

                &:first-child {
                    border-left-width: 0;
                }
            }
        }
    }

    @include breakpoint(large) {

        .block-links {
            width: calc(100% + 2.5rem);
            margin: 0;

            .cell {
                &:first-child {
                    border-left-width: 1px;
                }
            }
        }
    }
}
