﻿.excel-download-button {
    background-image: url('/images/excel-logo.png');
    background-position: left center;
    background-repeat: no-repeat;
    min-height: 47px;
    display: inline-block;
    padding: .8125rem 4rem .75rem 6rem;
    position: relative;
    z-index: 1;
    font-size: .875rem;
    text-transform: uppercase;
    letter-spacing: 2px;
    color: $black;
    font-weight: 600;

    &::before {
        content: '';
        position: absolute;
        right: 0;
        top: 0;
        width: calc(100% - 5rem);
        height: 100%;
        background-color: $white;
        z-index: -1;
        transition: background-color .3s;
    }

    &::after {
        content: '\f023';
        font-family: $iconFont;
        position: absolute;
        right: 1.75rem;
        top: 50%;
        margin-top: -.9375rem;
        font-size: 1.25rem;
    }

    &:hover {
        color: $white;
        &::before {
            background-color: $green;
        }
    }
}
