﻿.buy-direct-button {
    min-width: 220px;
    min-height: 70px;
    background-color: $yellow;
    padding: .875rem 1.5rem .8125rem;
    display: inline-flex;
    align-items: center;
    color: $black;
    transition: .3s all;
    font-size: 1rem;
    text-transform: uppercase;
    font-weight: 600;
    letter-spacing: 2px;
    justify-content: space-between;

    &:hover {
        color: $black;
        background-color: lighten($yellow, 10%);
    }

    &:focus, &:active {
        color: $black;
        background-color: $yellow;
    }

    &::after {
        content: '\f019';
        font-size: 1.5rem;
        margin-left: 1rem;
        font-family: $iconFont;
    }

    &.buy-direct-button--wide {
        width: 370px;
        max-width: 100%;
    }
}
