﻿.widget-social-links {
    text-align: center;
    width: 800px;
    max-width: 100%;
    margin: 0 auto;
    h3 {
        color: $yellow;
        margin-bottom: 1rem;
    }

    .inline-list {
        display: inline-flex;

        a{
            font-size: 2.5rem;
            color: $white;
            &:hover {
                color: $yellow;
            }
        }
    }
}
