﻿.widget-page-box-links {
    padding: $smallGutters * 2;
    text-align: center;

    h5 {
        font-size: 1.75rem;
        margin-bottom: 0;
        color: $yellow;
    }

    > .grid-x {
        margin-bottom: -$smallGutters * 2;
    }

    .outer {
        padding: 1.5rem 2rem;
        margin-bottom: $smallGutters * 2;
        background-size: cover;
        background-position: center;
    }

    p, h4 {
        margin-bottom: 1.5rem;
    }

    .product-card {
        padding: 0;
    }

    .products {
        list-style-type: none;
    }

    .cell {
        margin-bottom: 4rem;
    }

    .fill {
        opacity: .7;
    }

    .link-container {
        margin-top: 2rem;
    }

    &.layout-products {
        .outer {
            border: 1px solid $white;
        }
    }

    .svg {
        height: 242px;
    }

    @include breakpoint(medium) {
        padding: 2.5rem;

        > .grid-x {
            margin: 0 -1.25rem -2.5rem;
        }

        .outer {
            margin: 0 1.25rem 2.5rem;
            width: calc(50% - 2.5rem);
            padding: 5rem 6rem;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: space-between;
            min-height: 770px;
        }

        .products {
            margin-bottom: 0;
        }
    }
}
