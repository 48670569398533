﻿.widget-brands {
    &.layout-brands {
        background-color: $blue;

        .rte {
            margin-bottom: 1.5rem;
        }

        .content {
            max-width: $grid-container + 6rem;
            margin: 0 auto;
            padding: 0 #{$smallGutters};
        }

        .brand-target-container {
            max-width: 500px;
            width: 100%;
        }

        .brand-target {
            display: none;
            position: absolute;
            top: 0;
            left: 0;
            border: 2px solid rgba($white,.4);
            text-align: center;
            padding: 3rem 2rem;
        }

        .logo {
            min-height: 50px;
            margin-bottom: 3rem;
        }

        .image {
            margin-bottom: -5rem;
        }

        .inline-list {
            margin: 0 -1.5rem .5rem;

            li {
                margin: 0 1.5rem 1rem;
            }

            a {
                color: $white;
            }
        }

        .brand-tab {
            opacity: .7;
            font-size: 1.5rem;

            &::after {
                content: '';
                display: block;
                width: 0;
                transition: width .3s;
                background-color: $yellow;
                height: 3px;
            }

            &:hover {
                opacity: .9;
            }

            &.active {
                opacity: 1;
            }

            &:hover, &.active {
                &::after {
                    width: 100%;
                }
            }
        }

        .category-brand {
            display: block;
            width: 7rem;
            margin: 0 1.5rem 1rem;
            text-align: center;
            transition: all .3s;

            &.hidden {
                margin-left: 0;
                margin-right: 0;
                width: 0;
            }

            a.brand-trigger {
                display: flex;
                align-items: center;
                justify-content: center;
                height: 100%;

                img {
                    width: 100%;
                    height: auto;
                }
            }
        }

        .brands {
            margin: 0 -1.5rem -1rem;
        }

        .brand-trigger {
            transition: .3s opacity;
            opacity: .4;

            img {
                min-height: 70%;
                max-height: 100%;
            }
        }

        &.variant-brands {
            background-color: $black;

            .background {
                &::after {
                    background-image: linear-gradient(to right, $black 50%, transparent);
                }
            }

            .right-aligned {
                .background {
                    &::after {
                        background-image: linear-gradient(to left, $black 50%, transparent);
                    }
                }
            }
        }

        @include breakpoint(large) {
            background-color: transparent;

            .rte {
                margin-bottom: 2.5rem;
            }

            .brand-tab {
                font-size: 1.875rem;
            }

            .inline-list {
                margin-bottom: 2.5rem;
            }

            .category-brand {
                margin-bottom: 2rem;
            }

            .brands {
                margin-bottom: 2rem;
            }

            .rte {
                margin-bottom: 4.5rem;
            }

            .background {
                .fill {
                    width: 50%;
                    left: auto;
                    right: 0;
                    background-size: cover;
                    background-position: left center;
                }

                &::after {
                    content: '';
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    background-image: linear-gradient(to right, $blue 50%, transparent);
                    z-index: 3;
                }
            }

            .brand-trigger {
                &:hover {
                    opacity: .8;
                }

                &.active {
                    opacity: 1;
                }
            }

            .brand-target {
                padding: 6rem 4.5rem;
                width: 100%;

                &.active {
                    display: block;
                }
            }

            .logo {
                margin-bottom: 4.5rem;
            }

            .brand-target {

                p {
                    margin-bottom: 6rem;
                }
            }

            .image {
                margin-bottom: -10rem;
            }

            .brand-info {
                position: relative;
            }

            .brand-target-container {
                position: absolute;
                right: 0;
            }

            .right-aligned {
                .content > .grid-x {
                    flex-direction: row-reverse;
                }

                .background {
                    .fill {
                        right: auto;
                        left: 0;
                        background-position: right center;
                    }

                    &::after {
                        background-image: linear-gradient(to left, $blue 50%, transparent);
                    }
                }

                .brand-target-container {
                    right: auto;
                    left: 0;
                }
            }

            &.variant-brands {
                .right-aligned {
                    .background {
                        &::after {
                            background-image: linear-gradient(to left, $black 50%, transparent);
                        }
                    }
                }
            }

            &.variant-brands-by-category {
                .tab-background {
                    display: none;

                    &.active {
                        display: block;
                    }
                }
            }
        }
    }

    &.layout-cloud {
        ul {
            list-style-type: none;
            text-align: center;
            margin: 0 -1.5rem -2rem;
        }

        .cell {
            margin: 0 1.5rem 2rem;
            width: calc(50% - 3rem);
        }

        img {
            min-width: 50%;
            min-height: 50px;
        }

        @include breakpoint(medium) {
            .cell {
                width: calc(33.33333% - 3rem)
            }
        }
    }
}

.reveal.brand-reveal {
    text-align: center;
    background-color: $blue;
    &.dark {
        background-color: $black;
    }

    p, .close-button {
        color: $white;
    }

    .close-button {
        &::after {
            border-color: $white;
        }
    }

    .logo {
        width: 200px;
        height: 50px;
        margin-bottom: 2rem;
    }
}
