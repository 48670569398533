﻿.widget-dealer-finder {
    &.layout-default, &.layout-large-title {
        margin: 0 auto;
        max-width: 640px;
        text-align: center;

        location-search {
            display: inline-flex;
            width: 417px;
            max-width: 100%;
        }

        p {
            margin-bottom: 1.5rem;
            font-size: 1rem;
            font-weight: 600;
        }

        .buttons {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            margin: 0 -.875rem -1.5rem;
        }

        .location {
            margin-bottom: 2.5rem;
        }

        .custom-radio {
            margin: 0 .875rem 1.5rem;

            label {
                min-width: 195px;
            }
        }

        button {
            margin-top: 3.5rem;
        }
    }

    &.layout-default {
        h3 {
            font-size: 1.875rem;
            line-height: 2.25rem;
            letter-spacing: .25px;
            text-transform: none;
            margin-bottom: 2.25rem;
            font-family: $barlow;
            font-weight: 600;
        }
    }

    &.layout-search-by-name {
        a{
            margin-top: 2rem;
            width: 258px;
        }
    }
}
