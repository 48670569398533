﻿.reveal {

    &.standard {
        padding: 4.5rem #{$smallGutters} 1.5rem;
        background-color: $white;

        h1, h2, h3, h4, h5, h6, ol, ul, p {
            color: $black;
        }

        .close-button {
            right: $smallGutters;
            top: 1.75rem;
        }

        @include breakpoint(medium) {
            padding: 4.5rem 6rem;

            &-small {
                padding: 2.5rem 3rem;
            }

            .close-button {
                right: 2.5rem;
            }
        }
    }
}

.reveal-overlay {
    background: rgba(10,10,10,0.65);
}