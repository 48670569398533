﻿&.variant-news {
    @include breakpoint(medium) {
        padding: 0 2.5rem;

        .controls {
            .prev {
                left: -2.1625rem;
            }

            .next {
                right: -2.1625rem;
            }
        }
    }
}
