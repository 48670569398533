﻿.zoom-panel {
    position: fixed;
    top: 174px;
    height: calc(100vh - 190px);
    border: 2px solid $white;
    background-color: $black;
    z-index: 2;
    background-repeat: no-repeat;
    background-position: left top;
    display: none;
    &.active {
        display: block;
    }
}

.zoom-overlay {
    position: absolute;
    background-color: $yellow;
    opacity: .3;
    pointer-events: none;
    top: 0;
    left: 0;
    z-index: 2;
    display: none;
}

.zoom-overlay-container {
    position: relative;
    &.active {
        cursor: crosshair;
        .zoom-overlay {
            display: block;
        }
    }
}

@include breakpoint(medium down) {
    .zoom-overlay-container {
        height: 100%;
    }
}