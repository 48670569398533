﻿.back-button {
    text-transform: uppercase;
    font-size: .875rem;
    letter-spacing: 1.5px;
    color: $black;
    font-weight: 600;
    vertical-align: middle;

    &::before {
        font-family: $iconFont;
        border: 1px solid $black;
        content: '\f02d';
        font-size: .75rem;
        line-height: 27px;
        text-align: center;
        display: inline-block;
        margin-right: .75rem;
        width: 27px;
        height: 27px;
        transition: all .3s;
        vertical-align: middle;
        padding-left: 1px;
        position: relative;
        top: -1px;
    }

    &:hover {
        color: $yellow;

        &::before {
            border-color: $yellow;
            background-color: $yellow;
            color: $black;
        }
    }

    &.light {
        color: $white;

        &::before {
            border-color: $white;
        }

        &:hover {
            color: $yellow;

            &::before {
                border-color: $yellow;
                color: $white;
            }
        }
    }
}
