﻿.button {
    min-width: 200px;
    padding: .875rem 1.5rem .8125rem;
    line-height: 1.625rem;
    text-align: center;
    display: inline-block;
    font-size: 1rem;
    font-weight: 700;
    color: $black;
    background-color: $yellow;
    text-transform: uppercase;
    transition: .3s all;
    letter-spacing: 1.5px;

    i {
        margin-left: 1.5rem;
        font-size: 1.5rem;
    }

    &.active, &:hover, &:focus {
        cursor: pointer;
        color: $black;
        background-color: lighten($yellow, 10%);
    }

    &--white {
        background-color: $white;

        &.active, &:hover {
            background-color: darken($white, 10%);
        }
    }

    &--grey {
        background-color: $grey-lighter;

        &.active, &:hover {
            background-color: darken($grey-lighter, 10%);
        }
    }

    &--outline {
        transition: color .3s, border-color .3s;
        border: 1px solid $yellow;
        color: $white;
        background-color: transparent;

        &:hover {
            color: $white;
        }

        &.button--white {
            border-color: $white;

            &:hover {
                background-color: transparent;
                border-color: $yellow;
                color: $yellow;
            }
        }

        &.button--black {
            border-color: $black;
            color: $black;

            &:hover {
                background-color: transparent;
                border-color: $yellow;
                color: $yellow;
            }
        }
    }

    &--large {
        font-family: $barlowCondensed;
        font-size: 1.25rem;
        padding: 1.6875rem 2rem 1.625rem;
    }

    &--previous, &--next {
        position: relative;

        &::before {
            font-family: $iconFont;
            color: $black;
            font-size: 1.75rem;
            position: absolute;
            top: 50%;
            margin-top: -.8125rem;
            font-weight: 500;
        }
    }

    &--previous {
        padding-left: 4.25rem;

        &::before {
            content: '\f02d';
            left: 1.25rem;
        }
    }

    &--next {
        padding-right: 4.25rem;

        &::before {
            content: '\f02c';
            right: 1.25rem;
        }
    }

    &[disabled] {
        background-color: $grey;
        position: relative;
        transition: none;

        &, &::before {
            color: $grey;
        }

        &::after {
            color: $black;
            font-family: $iconFont;
            animation: fa-spin 2s infinite linear;
            content: '\f028';
            font-size: 2rem;
            position: absolute;
            top: 50%;
            left: 50%;
            margin-left: -1rem;
            margin-top: -1rem;
        }
    }
}
